<template>
  <div>
  
    <v-container style="padding-top:4rem">
      <div style="margin-bottom:15px">
        <v-row>
          <v-col cols="auto">
            <v-btn color="secondary" @click="goHome()" style="margin-right:2px">
 Ga terug
    </v-btn>
            <v-dialog transition="dialog-top-transition" max-width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on"
                  >Blog toevoegen</v-btn
                >
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark>Blog toevoegen</v-toolbar>
                  <v-card-text>
                    <label class="typo__label">Afbeelding toevoegen </label>
                    <div
                      id="my-strictly-unique-vue-upload-multiple-image"
                      style="display: flex; justify-content: left"
                    >
                      <vue-upload-multiple-image
                        @upload-success="uploadImageSuccess"
                        @before-remove="beforeRemove"
                        @edit-image="editImage"
                        :max-image="20"
                        :data-images="images"
                        idUpload="myIdUpload"
                        editUpload="myIdEdit"
                        dragText="Sleep afbeeldingen"
                        browseText="of klik om te selecteren"
                        dropText="Zet uw bestanden hier neer"
                        markIsPrimaryText="Instellen als standaard"
                        primaryText="standaard"
                        popupText="Dit is de standaard afbeelding"
                      ></vue-upload-multiple-image>
                    </div>
                    <div class="text-h2 pa-12">
                      <v-text-field
                        v-model="form.title"
                        label="Title"
                        outlined
                        clearable
                      ></v-text-field>

                     <vue-editor id="editor" v-model="form.blog" >
      </vue-editor>
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Sluiten</v-btn>

                    <v-btn text @click="addBlog()">Opslaan</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
      </div>

      <v-expansion-panels class="mb-6">
        <v-expansion-panel v-for="item in items" :key="item.update_id">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content
            >{{ item.Blog }}

            <div class="group">
              <!-- <v-btn class="button" dark small color="green">
                <v-icon>mdi-pencil</v-icon>
              </v-btn> -->

              <v-btn
                class="button"
                dark
                small
                color="red"
                @click="deleteBlog(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import VueUploadMultipleImage from "vue-upload-multiple-image";
export default {
  components: {
    VueUploadMultipleImage,
       VueEditor,
  },
  data() {
    return {
      form: {
        title: "",
        blog: "",
      },
      images: [],
      formDataImages: [],
      items: [],
    };
  },
  mounted() {
    this.getBlogsItems();
  },
  methods: {
    resetForm() {
      this.title = "";
      this.blog = "";
    },
        goHome(){
    this.$router.push('/AdminPortaal');
    },
    async getBlogsItems() {
      await axios
        .get("https://server.kantoorflex.nl/api/rest/v2/blog")
        .then((response) => (this.items = response.data));
    },
    async deleteBlog(item) {
      const result = await this.$swal.fire({
        title: "Ben je zeker?",
        text: "Je kunt dit niet herstellen!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijder",
      });
      if (result.isConfirmed) {
        await axios.delete(
          `https://server.kantoorflex.nl/api/rest/v2/blog/${item.blog_id}`
        );
        this.$swal.fire("Verwijderd!", "blog is verwijderd.", "success");
        await this.getBlogsItems();
        this.resetForm();
      }
    },

    async addBlog() {
      try {
        const { data } = await axios.post(
          `https://server.kantoorflex.nl/api/rest/v2/blog`,
          {
            title: this.form.title,
            Blog: this.form.blog,
          }
        );
        await Promise.all(
          this.formDataImages.map(async (formData) => {
            await axios.post(
              `https://server.kantoorflex.nl/api/rest/v2/blog/${data.id}/image`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          })
        );
        this.$swal.fire("Aangemaakt!", "", "success");

        this.getBlogsItems();
        this.resetForm();
        this.dialog = false;
      } catch (err) {
        console.log(err);
        this.$swal.fire("Controleer of alle velden zijn ingevuld", "", "error");
      }
    },

    uploadImageSuccess(formData, index, fileList) {
      console.log(formData, index, fileList);
      this.$set(this.formDataImages, index, formData);
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
      } else {
        this.$Swall.fire("error");
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
      //this.$set(this.formDataImages, index, formData);
    },

    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
  },
};
</script>

<style scoped>
.button {
  margin: 5px;
}
.group {
  margin-top: 10px;
  float: right;
}
</style>
